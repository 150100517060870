import * as firestore from "firebase/firestore";
import { ItemDescription, useUsers } from "./App";
import { db } from "./db";

export default function Dashboard() {
  const { users } = useUsers();

  const sortedUsers = [...users].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        alignItems: "center",
      }}
    >
      <h3>Dashboard</h3>
      <div style={{ display: "flex", flexDirection: "column", width: "25rem" }}>
        {sortedUsers.map((user) => (
          <div
            key={user.id}
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "1.25rem",
              padding: "1rem 0",
            }}
          >
            <h2 style={{ marginBottom: "0.5rem" }}>{user.name}</h2>
            <span>
              <b>Santa:</b>{" "}
              <select
                style={{ marginLeft: "0.5rem", fontSize: "1.25rem" }}
                value={user.santa}
                onChange={(e) => {
                  const newSanta = e.target.value;
                  firestore.updateDoc(
                    firestore.doc(firestore.collection(db, "people"), user.id),
                    { santa: newSanta }
                  );
                }}
              >
                <option>No Santa Assigned</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </span>
            <a
              href={`/${user.id}`}
              style={{ margin: "0.5rem 0" }}
            >
              Their page
            </a>
            <h3 style={{ margin: 0 }}>Their wishlist</h3>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {Object.entries(user.items || {}).map(([itemId, item]) => {
                return (
                  <div
                    key={itemId}
                    style={{
                      marginTop: "0.5rem",
                      borderTop: "1px solid black",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        margin: 0,
                        fontSize: "0.75rem",
                        textTransform: "uppercase",
                      }}
                    >
                      Name
                    </span>
                    <span>{item.name}</span>
                    <br />

                    <span
                      style={{
                        margin: 0,
                        fontSize: "0.75rem",
                        textTransform: "uppercase",
                      }}
                    >
                      Description
                    </span>
                    <ItemDescription description={item.description} />
                    <br />

                    <span
                      style={{
                        margin: 0,
                        fontSize: "0.75rem",
                        textTransform: "uppercase",
                      }}
                    >
                      Purchased by someone?
                    </span>
                    <span>{item.purchaser ? "Yes" : "No"}</span>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
