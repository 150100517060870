// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import * as firestore from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAKhKIZicK8mgOXtQHWnrmVcRvdMwiJzw8",
  authDomain: "wishlist-a54db.firebaseapp.com",
  projectId: "wishlist-a54db",
  storageBucket: "wishlist-a54db.appspot.com",
  messagingSenderId: "193737928323",
  appId: "1:193737928323:web:a6a5edbe3a0a7d8aedb5b6",
  measurementId: "G-7VHJCJBTX2",
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = firestore.getFirestore(app);

// (1) Print a JSON dictionary mapping a document ID to the person's name
async function getNameToIdMapping() {
  const peopleCollection = firestore.collection(db, "people");
  const peopleSnapshot = await firestore.getDocs(peopleCollection);

  const nameToIdMapping: Record<string, string> = {};
  peopleSnapshot.forEach((doc) => {
    nameToIdMapping[doc.data().name] = doc.id;
  });

  return nameToIdMapping;
}

// (2) Update the collection's "santa" values given a {name: santa} mapping and the ID-to-name mapping
async function updateSantaAssignments(nameToSantaMapping: Record<string, string>, nameToId: Record<string, string>) {
  for (const [name, santaName] of Object.entries(nameToSantaMapping)) {
    const personId = nameToId[name];
    const santaId = nameToId[santaName];

    if (personId && santaId) {
      const personDoc = firestore.doc(db, "people", personId);
      await firestore.updateDoc(personDoc, { santa: santaId });
    } else {
      console.error(`Invalid mapping: ${name} or ${santaName} not found.`);
    }
  }
}

// (3) Reset all items for each person
async function resetAllItems() {
  const peopleCollection = firestore.collection(db, "people");
  const peopleSnapshot = await firestore.getDocs(peopleCollection);

  for (const personDoc of peopleSnapshot.docs) {
    const personRef = firestore.doc(db, "people", personDoc.id);
    console.log("Resetting doc", personDoc.data().name);
    await firestore.updateDoc(personRef, {
      items: {
        "0": {
          description: "",
          link: "",
          name: "[Santa: Check this box if you aren't getting anything else from this list]",
          purchaser: "",
        }
      }
    });
  }
}

async function doUpdate() {
  const nameToId = await getNameToIdMapping();
  const nameToSantaMapping = {
    "Mohammad": "Erik",
    "Erik": "Mona",
    "Mona": "Jinny",
    "Jinny": "Darius",
    "Darius": "Fredrik",
    "Fredrik": "Alisann",
    "Alisann": "Renee",
    "Renee": "Mohammad"
  };

  // await updateSantaAssignments(nameToSantaMapping, nameToId)
  // await resetAllItems();

  // print the emails
  for (let [name1, name2] of [
    ["Darius", "Renee"],
    ["Mohammad", "Mona"],
    ["Fredrik", "Jinny"],
    ["Erik", "Alisann"]
  ]) {
    const displayNameMap: Record<string, string> = {
      ...Object.fromEntries(Object.keys(nameToSantaMapping).map(name => [name, name])),
      "Fredrik": "Dad",
      "Jinny": "Mom",
      "Mohammad": "P-pa",
    };
    const email = `Hello,

Year 3 of secret santa is here! Like the last 2 years, the website identifies who is using it based on the URL. Here are your links:

${displayNameMap[name1]}: https://santa-app.vercel.app/${nameToId[name1]}

${displayNameMap[name2]}: https://santa-app.vercel.app/${nameToId[name2]}

Happy holidays! 🎅
Michael`;

    console.log(email);
    console.log("\n\n--------\n\n")
  }
}

// doUpdate().catch(console.error)