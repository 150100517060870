import * as firestore from "firebase/firestore";
import { CollectionReference, DocumentReference } from "firebase/firestore";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { db } from "./db";
import { v4 as uuid4 } from "uuid";
import UpdateAssignments from "./Dashboard";

type Item = {
  purchaser: string;
  name: string;
  description: string;
};

interface User {
  id: string;
  name: string;
  santa?: string;
  items?: Record<string, Item>;
}

export function useUsers() {
  const [users, setUsers] = useState<User[]>([]);
  const [userDocRefs, setUserDocRefs] = useState<DocumentReference<User>[]>([]);

  useEffect(() => {
    const users = firestore.collection(
      db,
      "people"
    ) as CollectionReference<User>;
    firestore.getDocs(users).then(({ docs: userDocs }) => {
      setUsers(userDocs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setUserDocRefs(userDocs.map((doc) => doc.ref));
      userDocs.forEach((user, i) => {
        firestore.onSnapshot(user.ref, (snapshot) => {
          const data = snapshot.data();
          setUsers((users) => {
            const newUsers = [...users];
            if (data) {
              newUsers[i] = { ...data, id: user.id };
            }
            return newUsers;
          });
        });
      });
    });
  }, []);

  const purchase = useCallback(
    (purchaseeId: string, purchaserId: string, itemId: string) => {
      const purchaseeRef = userDocRefs.find((ref) => ref.id === purchaseeId);
      if (!purchaseeRef) {
        return;
      }
      // @ts-ignore
      firestore.updateDoc(purchaseeRef, {
        [`items.${itemId}.purchaser`]: purchaserId,
      });
    },
    [userDocRefs]
  );

  return {
    users,
    purchase,
  };
}

function AddItemSection() {
  const [opened, setOpened] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [newItemDescription, setNewItemDescription] = useState("");
  const [status, setStatus] = useState("");

  const addItem = useCallback(() => {
    if (newItemName === "") {
      setStatus("Item needs to have a name");
      return;
    }
    const collection = firestore.collection(db, "people");
    const me = window.location.pathname.slice(1);
    const userRef = firestore.doc(collection, me);
    const newItemId = uuid4();
    setStatus("Adding item " + newItemName + "...");
    firestore
      .updateDoc(userRef, {
        [`items.${newItemId}`]: {
          // @ts-ignore
          purchaser: "",
          link: "",
          name: newItemName,
          description: newItemDescription,
        },
      })
      .then(() => {
        setStatus("Added item " + newItemName);
        setNewItemDescription("");
        setNewItemName("");
      })
      .catch((e) => {
        setStatus("Error adding item " + newItemName);
      });
  }, [newItemDescription, newItemName]);

  const cancel = useCallback(() => {
    setNewItemDescription("");
    setNewItemName("");
    setOpened(false);
  }, []);

  if (!opened) {
    return (
      <div>
        <button onClick={() => setOpened(true)}>
          Add another gift idea 🎁
        </button>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem",
      }}
    >
      <h3 style={{ margin: 0 }}>Add gift idea 🎁</h3>
      <p style={{ marginBottom: "0.25rem" }}>Item name</p>
      <input
        type="text"
        onChange={(e) => setNewItemName(e.target.value)}
        value={newItemName}
        style={{ padding: "0.5rem" }}
      />
      <p style={{ marginBottom: "0.25rem" }}>
        This text box is for additional information to help other people buy you
        a gift. For example, a link to purchase the item or any preferences you
        may have.
      </p>
      <textarea
        cols={30}
        rows={5}
        onChange={(e) => setNewItemDescription(e.target.value)}
        style={{ padding: "0.5rem" }}
        value={newItemDescription}
      />
      <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
        <button onClick={addItem}>Add</button>{" "}
        <button onClick={cancel}>Cancel</button>
      </div>
      {status && <span style={{ marginTop: "0.25rem" }}>{status}</span>}
    </div>
  );
}

export function ItemDescription({ description }: { description: string }) {
  return (
    <pre
      style={{
        fontFamily: "-apple-system, sans-serif",
        whiteSpace: "normal",
        maxWidth: "100%",
        margin: 0,
      }}
    >
      {linkify(description.trim()) || "No description"}
    </pre>
  );
}

function EditableItem({
  itemId,
  item,
  index,
}: {
  itemId: string;
  item: Item;
  index: number;
}) {
  const changeTimerIdRef = useRef<NodeJS.Timeout>();
  const [editing, setEditing] = useState(false);
  return (
    <div
      key={itemId}
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem 0 0.5rem",
        borderTop: index > 0 ? "1px solid gray" : undefined,
      }}
    >
      <h3 style={{ margin: 0 }}>{item.name || "Unnamed item"}</h3>
      {editing ? (
        <>
          <p style={{ marginBottom: "0.25rem" }}>
            This text box is for additional information to help other people buy
            you a gift. For example, a link to purchase the item or any
            preferences you may have. Any updates you make here will be
            automatically saved.
          </p>
          <textarea
            cols={30}
            rows={5}
            onChange={(e) => {
              if (changeTimerIdRef.current) {
                clearTimeout(changeTimerIdRef.current);
              }
              changeTimerIdRef.current = setTimeout(() => {
                const collection = firestore.collection(db, "people");
                const me = window.location.pathname.slice(1);
                const userRef = firestore.doc(collection, me);
                firestore.updateDoc(userRef, {
                  [`items.${itemId}.description`]: e.target.value,
                });
                changeTimerIdRef.current = undefined;
              }, 500);
            }}
            style={{
              padding: "0.5rem",
              fontFamily: "-apple-system, sans-serif",
            }}
            defaultValue={item.description}
          />
        </>
      ) : (
        <ItemDescription description={item.description} />
      )}
      <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
        <button onClick={() => setEditing(!editing)}>
          {editing ? "Stop editing" : "Edit"}
        </button>
      </div>
    </div>
  );
}

function linkify(text: string) {
  const parts = text.split(/(?=[\n\r ])/);
  return (
    <>
      {parts.map((part) => {
        if (part.trim().startsWith("http")) {
          const lengthWithoutStart = part.trimStart().length;
          const paddingAtStart = part.length - lengthWithoutStart;
          return (
            <Fragment key={part}>
              {part.slice(0, paddingAtStart)}
              <a href={part.trim()} rel="noreferrer" target="_blank">
                {part.slice(paddingAtStart)}
              </a>
            </Fragment>
          );
        }
        return <Fragment key={part}>{part}</Fragment>;
      })}
    </>
  );
}

function App() {
  const me = window.location.pathname.slice(1);
  const { users, purchase } = useUsers();
  const myUser = users.find((user) => user.id === me);

  if (me === "$dashboard") {
    return <UpdateAssignments />;
  }

  if (!me) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Santa App 🎅</h1>
        <p>Please check your email for a link to your personal page!</p>
      </div>
    );
  }

  if (!myUser) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Santa App 🎅</h1>
        <p>Please check your email for a link to your personal page!</p>
        <i>Your user wasn't found.</i>
      </div>
    );
  }

  const width = "calc(min(40rem, 100%))";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
      }}
    >
      <h1 style={{ fontSize: "4rem", margin: "1rem 0rem" }}>Santa App 🎅</h1>
      <p>By Michael</p>
      {myUser && (
        <div
          key="me"
          style={{
            width,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1 style={{ textAlign: "center", marginTop: 0 }}>
            Your wishlist 📝
          </h1>
          <i style={{ display: "block", textAlign: "center" }}>
            You are {myUser.name}
          </i>
          <h2>Wish list</h2>
          {Object.entries(myUser.items || {}).length > 0 ? (
            [...Object.entries(myUser.items || {})]
              .sort((a, b) => (a[1].name || "").localeCompare(b[1].name || ""))
              .map(([itemId, item], index) => (
                <EditableItem
                  item={item}
                  itemId={itemId}
                  index={index}
                  key={itemId}
                />
              ))
          ) : (
            <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
              <p>
                You have no items on this wish list yet -- use the section below
                to add some 🎅
              </p>
            </div>
          )}
          <div
            style={{
              borderTop: "1px solid gray",
              margin: "0.5rem 0 1.5rem",
              height: 0,
              width: "100%",
            }}
          />
          <AddItemSection />
        </div>
      )}
      {users.map((user) => {
        if (user.id === me) {
          return null;
        }

        let items = Object.entries(user.items || {});
        // Sort by item name
        items = items.sort((a, b) => {
          const aName = a[1].name || "";
          const bName = b[1].name || "";
          return aName.localeCompare(bName);
        });

        return (
          <Fragment key={user.id}>
            <h1>{user.name}'s wishlist</h1>
            {me === user.santa && (
              <span>
                🤫 <b>You are {user.name}'s secret santa!</b>
              </span>
            )}
            <div
              key={user.id}
              style={{
                width,
                padding: "1rem",
              }}
            >
              <h2>Wish list</h2>
              {items.length > 0 ? (
                items.map(([itemId, item]) => {
                  const purchasedByAnybody = Boolean(item.purchaser);
                  const purchasedBySomeoneElse =
                    purchasedByAnybody && item.purchaser !== me;
                  return (
                    <div key={itemId}>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <div>
                          <input
                            type="checkbox"
                            style={{
                              marginRight: "0.5rem",
                              verticalAlign: "top",
                              border: "1px solid gray",
                              width: "1.25rem",
                              height: "1.25rem",
                            }}
                            checked={purchasedByAnybody}
                            disabled={purchasedBySomeoneElse}
                            onChange={(e) => {
                              if (e.target.checked) {
                                purchase(user.id, me, itemId);
                              } else {
                                purchase(user.id, "", itemId);
                              }
                            }}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span>
                            <b>{item.name}</b>
                            {purchasedByAnybody &&
                              (item.purchaser === me
                                ? " (purchased by you)"
                                : " (purchased by someone else)")}
                          </span>
                          <ItemDescription description={item.description} />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>{user.name} hasn't added any items to their wishlist yet</p>
              )}
            </div>
          </Fragment>
        );
      })}
      <div style={{ height: "50vh" }}></div>
    </div>
  );
}

export default App;
